<template>
	<div class="section-16 sect-mobile">
		<div class="container-section-mobile">
			<div class="title-section">Articole de suport detaliate pentru tine și colegii tăi</div>

			<div class="slider-tutorials">
				<swiper class="swiper" :options="swiperOption">
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Implementare</div>
								<div class="title">Ghid complet de implementare TBF Digital</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Management</div>
								<div class="title">Ce sunt obiectivele și rezultatele cheie</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Management</div>
								<div class="title">Întâlnirea zilnică, săptămânală și trimestrială</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Management</div>
								<div class="title">Structura de organizare a companiei</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Management</div>
								<div class="title">Cum definești viziunea companiei</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Management</div>
								<div class="title">Întrebări pentru stabilirea unui obiectiv bun</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Software</div>
								<div class="title">Cunoaște interfața TBF Digital</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Software</div>
								<div class="title">Cum adaugi obiective și rezultate cheie</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Software</div>
								<div class="title">Cum adaugi și cum evaluezi promisiunea zilnică</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Software</div>
								<div class="title">Cum îți adaugi colegi în TBF Digital</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Software</div>
								<div class="title">Cum folosești dashboardul pentru claritate</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Software</div>
								<div class="title">Cum lucrezi cu grupurile de obiective</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
					<swiper-slide>
						<div class="tutorial-slide">
							<div class="data">
								<div class="category">Software</div>
								<div class="title">Cum definești o strategie trimestrială</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test.png">
							</div>
						</div>
					</swiper-slide>
				</swiper>
			</div>

			<div class="view-all-tutorials">
				<button class="btn-view-tutorials">Gratuite cu abonamentul TBF <icon-arrow class="icon-arrow" /></button>
			</div>
		</div>
	</div>
</template>


<script>
	import IconArrow from '../../assets/landing/Arrow'

	export default {
		data() {
			return {
				swiperOption: {
					slidesPerView: 'auto',
					centeredSlides: true,
					roundLengths: true,
					spaceBetween: 40,
					nested: true,
					loop: true
				}
			}
		},
		components: {
			IconArrow
		},
		mounted(){
		}
	}
</script>